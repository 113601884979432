import * as THREE from 'three'
import React, {
  Suspense,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react'
import { Canvas, useLoader, useThree } from '@react-three/fiber'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'
import { MapControls } from '@react-three/drei'
import { isBrowser } from '@emotion/utils'

import svg from '../../images/max_map.svg'
import { useWindowDimensions } from '../../utils/hooks'
import './CustomMaterial'

const IMAGE_WIDTH = 620
const IMAGE_HEIGHT = 510

const Cell = ({ color, shape, fillOpacity, id, callback, canvasRef }) => {
  const [hovered, set] = useState(false)

  useEffect(
    () => void (canvasRef.current.style.cursor = hovered ? 'pointer' : 'auto'),
    [hovered]
  )

  return (
    <mesh
      onPointerOver={() => {
        if (id !== '') {
          set(true)
          callback(id)
        }
      }}
      onPointerOut={() => {
        if (id !== '') set(false)
      }}
      onClick={() => {
        if (id !== '') callback(id)
      }}
    >
      <customMaterial
        color={hovered ? '#ff484a' : color}
        opacity={fillOpacity}
        depthWrite={false}
        transparent
      />
      <shapeBufferGeometry args={[shape]} />
    </mesh>
  )
}

const Svg = ({ callback, canvasRef, position = [0, 0, 0] }) => {
  const { width } = useWindowDimensions()
  const scaleFactor = () => {
    if (width <= 320) return 0.4
    if (width <= 375) return 0.5
    if (width <= 425) return 0.6
    if (width <= 768) return 1
    if (width <= 1024) return 0.8
    return 1
  }

  const {
    viewport: { height },
  } = useThree()
  const { paths } = useLoader(SVGLoader, svg)

  const shapes = useMemo(
    () =>
      paths.flatMap(p =>
        p.toShapes(true).map(shape => ({
          shape,
          color: p.color,
          fillOpacity: p.userData.style.fillOpacity,
          id: p.userData.node.id,
        }))
      ),
    [paths]
  )

  const ref = useRef()
  useLayoutEffect(() => {
    const sphere = new THREE.Box3()
      .setFromObject(ref.current)
      .getBoundingSphere(new THREE.Sphere())
    ref.current.position.set(-sphere.center.x, -sphere.center.y, 0)
  }, [])

  return (
    <group
      ref={ref}
      position={position}
      rotation={[0, 0, 0]}
      scale={[
        ((height * (IMAGE_WIDTH / IMAGE_HEIGHT)) / IMAGE_WIDTH) * scaleFactor(),
        -(height / IMAGE_HEIGHT) * scaleFactor(),
        1,
      ]}
    >
      {shapes.map((props, index) => (
        <Cell
          key={props.shape.uuid}
          callback={callback}
          canvasRef={canvasRef}
          {...props}
          text={index % 20 || `Cell ${index}`}
        />
      ))}
    </group>
  )
}

const Map = props => {
  const canvasRef = useRef()

  return (
    <>
      <Canvas
        ref={canvasRef}
        frameloop="demand"
        orthographic
        camera={{ position: [0, 0, 50], zoom: 2, up: [0, 0, 1], far: 10000 }}
        gl={{ antialias: true }}
        dpr={Math.max(isBrowser ? window.devicePixelRatio : 0, 2)}
      >
        <Suspense fallback={null}>
          <Svg
            position={[15, 0, 0]}
            callback={props.callback}
            canvasRef={canvasRef}
          />
        </Suspense>
        <MapControls
          enablePan={false}
          enableZoom={false}
          enableRotate={false}
          {...props}
        />
      </Canvas>
    </>
  )
}

export default Map
