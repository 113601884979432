import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

import Seo from '~/components/seo'
import Navigation from '~/components/Navigation'
import ProductForm from '~/components/ProductForm'
import Footer from '~/components/Footer'
import Map from '../../components/InteraktiverGrundriss'
import ProductImages from '../../components/ProductImages'
import {
  Container,
  TwoColumnGrid,
  MainContent,
  breakpoints,
} from '~/utils/styles'
import text from '../../images/max_map_text.json'
import clickImage from '../../images/click.png'

const Image = styled.img`
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
`

const HeroWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content; center;
  align-items: center;
  margin-bottom: 10rem;

  animation: fadeIn 5s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const HeroImage = styled.div`
  width: 50%;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`

const H2 = styled.h2`
  font-size: 2.25rem;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: 'Old Standard TT', serif;
  font-weight: 400;
  margin: 0 0 0.5rem;
  line-height: 1.4;
  color: #ffe4b6;
`

const Text = styled.p`
  font-family: 'Old Standard TT', serif;
  line-height: 1.6;
  font-size: 1.2rem;
  color: #ffe4b6;
`

const ProductTitle = styled.h1`
  color: #ffe4b6;
  font-size: 2.25rem;
  margin-bottom: 15px;
  word-wrap: break-word;
  font-family: 'Old Standard TT', serif;
  font-weight: 400;
  margin: 0 0 0.5rem;
  line-height: 1.4;
  text-align: center;
`

const ProductDescription = styled.div`
  color: #ffe4b6;
  margin-top: 40px;
  font-weight: 300;

  a {
    color: inherit;
  }
`

const MaxPage = ({ data }) => {
  const product = data.shopifyProduct

  const [imageSource, setImageSource] = useState(clickImage)
  const [infoTitle, setInfoTitle] = useState('Interaktiver Karte')
  const [infoText, setInfoText] = useState(
    'Klicke auf eine Stecknadel um unterschiedliche Wahrzeichen zu sehen.'
  )

  const handleMapClick = id => {
    setImageSource(`/${id}.jpg`)
    setInfoTitle(text[id].title)
    setInfoText(text[id].text)
  }

  return (
    <div style={{ backgroundColor: 'black' }}>
      <Seo title={product.title} description={product.description} />
      <Navigation color="#ffe4b6" />
      <Container>
        <MainContent>
          <HeroWrapper>
            <HeroImage>
              <StaticImage
                src="../../images/max_hero.jpg"
                layout="fullWidth"
                alt="Nürnberg Panorama"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  marginBottom: '5rem',
                }}
              />
            </HeroImage>
            <H2>Ein Blick in die Seele der Stadt</H2>
            <Text>
              Unser Max Shirt - Kleidung mit Geschichte. Unser Motiv entstand
              auf der Maxbrücke in mitten von Nürnberg.
              <br />
              <br />
              Die Maxbrücke besteht aus massiven Sandsteinquadern und besitzt
              wundervolle Gusseisen Verzierungen. Die Brücke wurde 1457
              errichtet und trägt ihren Namen zu Ehren des bayrischen Königs
              Maximilian I. Joseph. Im Herzen der Stadt, verbindet die Brücke
              die Stadteile St. Lorenz &amp; St. Sebald.
            </Text>
          </HeroWrapper>

          <TwoColumnGrid style={{ marginTop: '10rem', color: '#ffe4b6' }}>
            <div style={{ width: 'auto', height: '500px' }}>
              <Map callback={handleMapClick} />
            </div>
            <div>
              <Image src={imageSource} alt="Kein Bild gefunden" />
              <h3>{infoTitle}</h3>
              <p>{infoText}</p>
            </div>
          </TwoColumnGrid>

          <TwoColumnGrid style={{ margin: '10rem 0' }}>
            <ProductImages product={product} />
            <div>
              <ProductTitle>{product.title}</ProductTitle>
              <ProductForm color="#ffe4b6" dark={true} product={product} />
              <ProductDescription
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
            </div>
          </TwoColumnGrid>
        </MainContent>
        <Footer color="#ffe4b6" />
      </Container>
    </div>
  )
}

export const query = graphql`
  query ($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId: storefrontId
        selectedOptions {
          name
          value
        }
      }
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 910
              placeholder: TRACED_SVG
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`

export default MaxPage
